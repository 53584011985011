// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Home.css */
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.sidebar-item {
    transition: background-color 0.3s;
}

.sidebar-item:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Lighten background on hover */
}

h5 {
    color: white; /* Sidebar title color */
}

.container {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 250px;
    background-color: #3f51b5; /* Sidebar background color */
    padding: 16px;
    color: white; /* Text color in sidebar */
}

.content {
    flex-grow: 1;
    padding: 16px;
    background-color: #f5f5f5; /* Content area background color */
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,SAAS;IACT,UAAU;IACV,iCAAiC;AACrC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,0CAA0C,EAAE,gCAAgC;AAChF;;AAEA;IACI,YAAY,EAAE,wBAAwB;AAC1C;;AAEA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,yBAAyB,EAAE,6BAA6B;IACxD,aAAa;IACb,YAAY,EAAE,0BAA0B;AAC5C;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB,EAAE,kCAAkC;AACjE","sourcesContent":["/* src/Home.css */\nbody {\n    margin: 0;\n    padding: 0;\n    font-family: 'Roboto', sans-serif;\n}\n\n.sidebar-item {\n    transition: background-color 0.3s;\n}\n\n.sidebar-item:hover {\n    background-color: rgba(255, 255, 255, 0.2); /* Lighten background on hover */\n}\n\nh5 {\n    color: white; /* Sidebar title color */\n}\n\n.container {\n    display: flex;\n    height: 100vh;\n}\n\n.sidebar {\n    width: 250px;\n    background-color: #3f51b5; /* Sidebar background color */\n    padding: 16px;\n    color: white; /* Text color in sidebar */\n}\n\n.content {\n    flex-grow: 1;\n    padding: 16px;\n    background-color: #f5f5f5; /* Content area background color */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
