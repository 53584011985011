// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.movie-detail {
    display: flex;
    justify-content: center; /* Center the card */
    margin: 20px;
}

.movie-card {
    width: 100%; /* Make the card wider */
    max-width: 800px; /* Set a maximum width */
    padding: 20px; /* Add padding for spacing */
}

.movie-title {
    margin-bottom: 10px;
    text-align: center; /* Center align text */
}

.movie-info {
    display: flex; /* Use flexbox for a two-column layout */
    justify-content: space-between; /* Space items evenly */
    margin-bottom: 20px; /* Space below the info section */
}

.movie-info-item {
    flex: 1 1; /* Each item takes equal space */
    margin: 0 10px; /* Space between the two columns */
}

.movie-description {
    margin-top: 20px; /* Space above the description */
    margin-bottom: 20px; /* Space below the description */
    text-align: center; /* Center align description text */
}

.small-movie-image {
    width: 100%; /* Adjust width as needed */
    max-width: 300px; /* Set a max width */
    border-radius: 8px; /* Optional: add border radius */
    display: block; /* Ensure it's a block element */
    margin: 0 auto; /* Center the image */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/MovieDetail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB,EAAE,oBAAoB;IAC7C,YAAY;AAChB;;AAEA;IACI,WAAW,EAAE,wBAAwB;IACrC,gBAAgB,EAAE,wBAAwB;IAC1C,aAAa,EAAE,4BAA4B;AAC/C;;AAEA;IACI,mBAAmB;IACnB,kBAAkB,EAAE,sBAAsB;AAC9C;;AAEA;IACI,aAAa,EAAE,wCAAwC;IACvD,8BAA8B,EAAE,uBAAuB;IACvD,mBAAmB,EAAE,iCAAiC;AAC1D;;AAEA;IACI,SAAO,EAAE,gCAAgC;IACzC,cAAc,EAAE,kCAAkC;AACtD;;AAEA;IACI,gBAAgB,EAAE,gCAAgC;IAClD,mBAAmB,EAAE,gCAAgC;IACrD,kBAAkB,EAAE,kCAAkC;AAC1D;;AAEA;IACI,WAAW,EAAE,2BAA2B;IACxC,gBAAgB,EAAE,oBAAoB;IACtC,kBAAkB,EAAE,gCAAgC;IACpD,cAAc,EAAE,gCAAgC;IAChD,cAAc,EAAE,qBAAqB;AACzC","sourcesContent":[".movie-detail {\n    display: flex;\n    justify-content: center; /* Center the card */\n    margin: 20px;\n}\n\n.movie-card {\n    width: 100%; /* Make the card wider */\n    max-width: 800px; /* Set a maximum width */\n    padding: 20px; /* Add padding for spacing */\n}\n\n.movie-title {\n    margin-bottom: 10px;\n    text-align: center; /* Center align text */\n}\n\n.movie-info {\n    display: flex; /* Use flexbox for a two-column layout */\n    justify-content: space-between; /* Space items evenly */\n    margin-bottom: 20px; /* Space below the info section */\n}\n\n.movie-info-item {\n    flex: 1; /* Each item takes equal space */\n    margin: 0 10px; /* Space between the two columns */\n}\n\n.movie-description {\n    margin-top: 20px; /* Space above the description */\n    margin-bottom: 20px; /* Space below the description */\n    text-align: center; /* Center align description text */\n}\n\n.small-movie-image {\n    width: 100%; /* Adjust width as needed */\n    max-width: 300px; /* Set a max width */\n    border-radius: 8px; /* Optional: add border radius */\n    display: block; /* Ensure it's a block element */\n    margin: 0 auto; /* Center the image */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
